<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <ValidationObserver
              ref="form"
              v-slot="{ invalid, validated, validate, handleSubmit, reset }"
            >
              <form
                @submit.prevent="handleSubmit(login)"
                @reset.prevent="reset"
                class="col-xl-10 col-lg-10 col-md-10 col-sm-12 ml-auto mr-auto"
              >
                <card type="login">
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 ml-auto mr-auto">
                      <div class="blocLogo">
                        <img
                          class="logoFormulaire align-middle"
                          alt="logo Thiriez Literie"
                          src="../../../../../public//help-center-icon-0.jpg"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 ml-auto mr-auto">
                      <h3 slot="header" class="header text-center">
                        Connexion
                      </h3>

                      <ValidationProvider
                        mode="lazy"
                        name="email"
                        ref="username"
                        rules="required|email"
                        v-slot="{ passed, errors }"
                      >
                        <fg-input
                          class="col-12"
                          v-model="form.username"
                          name="email"
                          addon-left-icon="fa fa-user"
                          placeholder="Email"
                          type="email"
                          required
                          :hasSuccess="passed"
                          @blur="resetInput()"
                        />
                        <p class="messageErreur">{{ errors[0] }}</p>
                      </ValidationProvider>

                      <!--  -->
                      <ValidationProvider
                        mode="lazy"
                        name="password"
                        ref="password"
                        rules="required|min:8|max:16"
                        v-slot="{ passed, errors }"
                      >
                        <fg-input
                          class="col-12"
                          v-model="form.password"
                          name="password"
                          addon-left-icon="fa fa-lock"
                          placeholder="Mot de passe"
                          type="password"
                          required
                          :hasSuccess="passed"
                          @blur="resetInput()"
                        ></fg-input>
                        <p class="messageErreur">{{ errors[0] }}</p>
                      </ValidationProvider>

                      <br />

                      <p-button
                        type="info"
                        native-type="submit"
                        slot="footer"
                        round
                        block
                        class="submit-button"
                        @click="validate"
                        :disabled="invalid || !validated"
                        >Se Connecter
                      </p-button>
                    </div>
                  </div>
                </card>
              </form>
            </ValidationObserver>
          </div>
        </div>
        <app-footer></app-footer>
        <div
          class="full-page-background"
          style="
            background-image: url(https://thiriez-literie.fr/wp-content/uploads/2015/08/ambiance-1600x800-ELEGANCE-1600x630.jpg);
          "
        ></div>
      </div>
    </div>

    <!-- small modal -->
    <modal
      :show.sync="modals.mini"
      class="modal-primary"
      :show-close="false"
      headerClasses="justify-content-center"
      type="mini"
    >
      <div slot="header" class="modal-profile ml-auto mr-auto">
        <i
          v-if="typeModal == 'check'"
          class="nc-icon nc-check-2"
          style="color: #4fc08d"
        ></i>
        <i
          v-if="typeModal == 'warning'"
          class="nc-icon nc-button-pause"
          style="color: #faad14"
        ></i>
        <i
          v-if="typeModal == 'error'"
          class="nc-icon nc-simple-remove"
          style="color: #c73424"
        ></i>
      </div>
      <h4 style="text-align: center">{{ titreModal }}</h4>

      <p style="text-align: center; padding: 10px; font-size: 20px">
        {{ descriptionModal }}
      </p>
      <div v-if="btn2 == ''">
        <p style="text-align: center; color: red">
          Le compte sera vérouillé après trois échecs.
        </p>
        <p style="text-align: center; color: red">
          Tentatives restantes : {{ tentativesRestantes }}
        </p>
      </div>
      <template slot="footer">
        <div class="left-side">
          <p-button
            v-if="btn1 != ''"
            type="default"
            link
            @click="modals.mini = false"
            >{{ btn1 }}</p-button
          >
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button v-if="btn2 != ''" type="default" link @click="closeModal">{{
            btn2
          }}</p-button>
          <p-button v-else type="default" link @click="closeModal"
            >Mot de passe oublié</p-button
          >
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import { Card, Checkbox, Button, Modal } from "src/components/UIComponents";
import NotificationTemplate from "../Components/NotificationTemplate";
import AppNavbar from "./Layout/AppNavbar";
import AppFooter from "./Layout/AppFooter";

import { ValidationObserver, ValidationProvider } from "vee-validate";
//ajouter des règles de validatione
import { extend } from "vee-validate";
import { required, email, max, regex } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Champ requis",
});
extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: "Saisissez au moins 8 caractères",
});
extend("max", {
  ...max,
  message: "Saisisez maximum 16 caractères",
});
extend("email", {
  ...email,
  message: "Veuillez respecter le format exemple@toto.fr",
});

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    ValidationObserver,
    ValidationProvider,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,

    Modal,
    NotificationTemplate,
  },
  mounted() {
    if (process.env.NODE_ENV === "development") {
      console.log("dev mode");
      this.form.username = "test2-thiriez@yopmail.com";
      this.form.password = "azerty123";
    } else {
      console.log("prod mode");
    }
  },
  data() {
    return {
      type: ["", "info", "success", "warning", "danger"],
      notifications: {
        topCenter: false,
      },
      modals: {
        classic: false,
        notice: false,
        mini: false,
      },
      form: {
        username: "",
        password: "",
      },
      visibleCheckModal: false,
      titreModal: "val titre",
      descriptionModal: "val description",
      typeModal: "error",
      btn1: "",
      btn2: "",
      tentativesRestantes: "3",
    };
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign) {
      const color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
        component: NotificationTemplate,
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
      });
    },
    setInfosModal(titre, description, typeModal, btn1, btn2) {
      this.titreModal = titre;
      this.descriptionModal = description;
      this.typeModal = typeModal;
      //this.visibleCheckModal = true;
      this.btn1 = btn1;
      this.btn2 = btn2;
    },
    closeModal() {
      this.modals.mini = true;

      const TokenProperties = JSON.parse(
        sessionStorage.getItem("TokenProperties")
      );
      let codeReponse = TokenProperties.code;
      if (codeReponse == 6) {
        this.$router.push("/register", () => {});
      } else {
        this.$router.push("/forgotPassword", () => {});
      }
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    validate() {
      return this.$refs.form.validate().then((res) => {
        if (!res) {
          return;
        }
        this.$emit("on-validated", res);
        console.log(res);
        return res;
      });
    },
    resetInput() {
      console.log("test reset");
      //this.$form.resetFields();
      if (this.form.username === "" && this.form.password === "") {
        console.log("entrée dans if");
        this.$refs.form.reset();
      }
    },
    async handleSubmit() {
      console.log("handleSubmit");
      this.buttonDisabled = true;
      //e.preventDefault();
      const credentials = {
        username: this.form.username,
        password: this.form.password,
      };
      await this.$store.dispatch("getTokenProperties", credentials);
    },
    async login() {
      console.log("login");
      // this.$refs.form.validate((valid) => {
      //   if (valid) {
      console.log("valid");
      await this.handleSubmit();
      const TokenProperties = JSON.parse(
        sessionStorage.getItem("TokenProperties")
      );
      let descriptionReponse = TokenProperties.description;
      let codeReponse = TokenProperties.code;
      this.tentativesRestantes = TokenProperties.data;
      console.log("description : " + descriptionReponse);
      console.log("code : " + codeReponse);
      if (codeReponse != 0) {
        // this.setInfosModal(
        //   "Erreur",
        //   descriptionReponse,
        //   "error",
        //   "Réessayer",
        //   ""
        // );
        // this.modals.mini = true;
        this.$notify({
          type: "danger",
          title: "Erreur d'authentification",
          message: descriptionReponse,
          icon: "nc-icon nc-app",
          horizontalAlign: "right",
          verticalAlign: "top",
        });
        if (codeReponse === 6) {
          this.form.username = "";
          this.btn2 = "Créer un compte";
        } else if (codeReponse === 9) {
          this.form.password = "";
        } else {
          this.form.username = "";
          this.form.password = "";
        }
      } else {
        //this.showModal('Réussite', descriptionReponse, 'check', 'Accéder au site', '');
        //this.closeModal();
        this.$router.push("/liste-utilisateurs", () => {});
      }
      // } else {
      //   console.log("erreurs dans le form !");
      //   //return false;
      // }
      // });
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style lang="scss" scoped>
.card .alert {
  position: relative !important;
  width: 100%;
}

.full-page > .content {
  vertical-align: middle;
}
.container {
  // margin-top: 10px;
  min-width: 256px;
  max-width: 900px;
}

.blocLogo {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 300px;
  margin: auto;
}
img.logoFormulaire {
  width: 100%;
  margin: auto;
  padding-left: 32px;
  padding-right: 32px;
}

.input-group-text {
  font-size: 16px;
  padding: 10px 10px 10px 30px;
}

.btn.submit-button {
  color: #fff;
  background-color: #002043;
  width: 50%;
  margin: auto;
}
.btn.submit-button:hover,
.btn.submit-button:focus {
  color: #002043;
  background-color: #fff;
}

/* ################ Les Boutons et Liens ################ */
.divLiens {
  margin: auto;
  width: 100%;
  align-self: center;
}
.mesLiens {
  margin: auto;
  text-align: center;
  font-size: 12px;
  color: #002043;
  width: auto;
  cursor: default;
  &-pass {
    color: #002043;
    width: auto;
  }
  &-creaCompte {
    color: #002043;
    width: auto;
  }
}
.mesLiens:hover {
  color: #004794;
}
</style>
