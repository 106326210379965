//Guard
import Welcome from '../components/Welcome.vue'

import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Overview.vue')
const Widgets = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Widgets.vue')

// Pages
import ListeUtilisateurs from 'src/components/Dashboard/Views/Pages/ListeUtilisateurs.vue'
import Login from 'src/components/Dashboard/Views/Pages/Login.vue'
import Lock from 'src/components/Dashboard/Views/Pages/Lock.vue'
import Profile from 'src/components/Dashboard/Views/Pages/Profile.vue'

// Components pages
import Icons from 'src/components/Dashboard/Views/Components/Icons.vue'

let ListeUtilisateursMenu = {
  path: '/liste-utilisateurs',
  component: DashboardLayout,
  redirect: '/liste-utilisateurs',
  children: [
    {
      path: '/liste-utilisateurs',
      name: 'Liste-Utilisaeurs',
      component: ListeUtilisateurs
    }
  ]
}
let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/icons',
  children: [
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
  ]
}
let profileMenu = {
  path: '/profile',
  component: DashboardLayout,
  redirect: '/profile',
  children: [
    {
      path: '/profile',
      name: 'Profile',
      component: Profile
    }]
}

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/lock',
    name: 'Lock',
    component: Lock
  },
  profileMenu,
  componentsMenu,
  ListeUtilisateursMenu,
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      }
    ]
  },
  { path: '*', component: NotFound }
];

export default routes
