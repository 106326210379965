import adminRepository from '../repository/admin'
import loginRepository from '../repository/login'
import userRepository from '../repository/users'
import homeRepository from '../repository/home'
import routes from '../routes/routes.js'


const changePassword = async (context, email) => {
  await userRepository.forgotPassword(email).then(async function (res) {
    let code = res.data.code
    const description = res.data.description
    if (code !== 0) {
      if (code >= 5 && code <= 9) {
        console.log("erreur" + description); //.openNotificationWithIcon('error', 'Demande impossible', res.data.description)
      } else {
        console.log("erreur: " + description)
      }
    }
    else {
      routes.push({ name: 'SendMailAccount', params: { message: 'réinitialiser votre mot de passe.' } })
    }
  })
}

const getAdminInfo = async (context, token) => {
  let notif = { type: 'danger', title: 'Erreur', description: 'Erreur inconnue', icon: '' };
  const body = {
    "ACTION": "UTILISATEURS_OTL",
    "SOUS_ACTION": "LECTURE"
  }
  await adminRepository.getAdminData(token, body).then(async function (res) {
    const code = res.data.code;
    const description = res.data.description;
    notif.description = description;
    if (code !== 0) {
      notif.type = 'danger'; notif.title = 'Erreur'; notif.icon = 'nc-icon nc-simple-remove';
      if (code !== 402) {
        //.openNotificationWithIcon('error', 'Erreur de récupération des contacts', res.data.description)
        console.log("erreur: " + description);
      }
      else {
        removeToken(context)
        console.log("erreur: " + description); //.openNotificationWithIcon('error', 'Erreur de récupération des codes pays', res.data.description)
      }
    } else {
      notif.type = 'success'; notif.title = 'Succès'; notif.icon = 'nc-icon nc-check-2';
      const contacts = res.data.data;
      localStorage.setItem('adminInfos', JSON.stringify(contacts))
    }
  })
  return notif;
}

const updateAdminInfo = async (context, object) => {
  //console.log(object.token, '\n ', object.list);
  let notif = { type: 'danger', title: 'Erreur', description: 'Erreur inconnue', icon: '' };
  const body = {
    "ACTION": "UTILISATEURS_OTL",
    "SOUS_ACTION": "MODIFICATION",
    "UTILISATEURS": [
      {
        "CHAMPS": JSON.parse(object.list)
      }
    ]
  }
  await adminRepository.getAdminData(object.token, body).then(async function (res) {
    const code = res.data.code;
    const description = res.data.description;
    notif.description = description;
    if (code !== 0) {
      notif.type = 'danger'; notif.title = 'Erreur'; notif.icon = 'nc-icon nc-simple-remove';
      if (code !== 402) {
        //.openNotificationWithIcon('error', 'Erreur de récupération des contacts', res.data.description)
        console.log("erreur: " + description);
      }
      else {
        removeToken(context)
        console.log("erreur: " + description); //.openNotificationWithIcon('error', 'Erreur de récupération des codes pays', res.data.description)
      }
    } else {
      notif.type = 'success'; notif.title = 'Succès'; notif.icon = 'nc-icon nc-check-2';
      const contacts = res.data.data;
      localStorage.setItem('adminInfos', JSON.stringify(contacts))
    }
  })
  return notif;
}

const getContactsInfos = async (context, token) => {
  let notif = { type: '', title: '', description: '', icon: '' };
  await userRepository.getContacts(token).then(async function (res) {
    const code = res.data.code;
    const description = res.data.description;
    notif.description = description;
    if (code !== 0) {
      notif.type = 'danger'; notif.title = 'Erreur'; notif.icon = 'nc-icon nc-simple-remove';
      if (code !== 402) {
        //.openNotificationWithIcon('error', 'Erreur de récupération des contacts', res.data.description)
        console.log("erreur: " + description);
      }
      else {
        removeToken(context)
        console.log("erreur: " + description); //.openNotificationWithIcon('error', 'Erreur de récupération des codes pays', res.data.description)
      }
    } else {
      notif.type = 'success'; notif.title = 'OK'; notif.icon = 'nc-icon nc-check-2';
      const contacts = res.data.data;
      localStorage.setItem('contactsInfos', JSON.stringify(contacts))
    }
  })
  return notif;
}

const getDeliveryAddress = async (context, token) => {
  let notif = { type: '', title: '', description: '', icon: '' };
  await userRepository.getDeliveryAddress(token).then(async function (res) {
    const code = res.data.code
    const description = res.data.description
    if (code !== 0) {
      if (code !== 402)
        console.log("erreur: " + description); //.openNotificationWithIcon('error', 'Erreur de récupération des contacts', res.data.description)
      else
        removeToken(context)
      console.log("erreur: " + description); //.openNotificationWithIcon('error', 'Erreur de récupération des codes pays', res.data.description)
    } else {
      const address = res.data.data
      localStorage.setItem('deliveryAddress', JSON.stringify(address))
    }
  })
}

const getCountries = async (context, token) => {
  let notif = { type: '', title: '', description: '', icon: '' };
  const body = {
    "ACTION": "CODE_PAYS"
  }
  await userRepository.getUserCountries(token, body).then(async function (res) {
    const code = res.data.code
    const description = res.data.description
    if (code !== 0) {
      if (code !== 402)
        console.log("erreur: " + description); //.openNotificationWithIcon('error', 'Erreur de récupération des codes pays', res.data.description)
      else
        removeToken(context)
      console.log("erreur: " + description); //.openNotificationWithIcon('error', 'Erreur de récupération des codes pays', res.data.description)
    } else {
      const countries = res.data.data.VALEURS
      localStorage.setItem('countriesInfos', JSON.stringify(countries))
    }
  })
}

const getHomeContent = async (context, token) => {
  let notif = { type: '', title: '', description: '', icon: '' };
  const body = {
    "ACTION": "ACCEUIL"
  }
  await homeRepository.getHomeData(token, body).then(async function (res) {
    const code = res.data.code;
    const description = res.data.description;
    if (code !== 0) {
      notif.type = 'danger'; notif.title = 'Erreur'; notif.description = 'Erreur'; notif.icon = 'nc-icon nc-simple-remove';
      if (code !== 402)
        console.log("erreur: " + description); //.openNotificationWithIcon('error', 'Erreur de récupération de l\'accueil', res.data.description)
      else
        removeToken(context)
      console.log("erreur: " + description); //.openNotificationWithIcon('error', 'Erreur de récupération de l\'accueil', res.data.description)
    } else {
      notif.type = 'success'; notif.title = 'OK'; notif.description = 'Récupération des dernières nouvelles réussie'; notif.icon = 'nc-icon nc-check-2';
      const homeContent = res.data.data.VALEURS
      localStorage.setItem('homeContent', JSON.stringify(homeContent))
    }
  })
  return notif;
}

const getTokenProperties = async (context, credentials) => {
  let notif = { type: '', title: '', description: '', icon: '' };
  await loginRepository.login(credentials.username, credentials.password)
    .then(async res => {
      const code = res.data.code
      const description = res.data.description

      console.log("description depuis getTokenProp : " + description)
      console.log("code depuis getTokenProp : " + code)

      sessionStorage.setItem('TokenProperties', JSON.stringify(res.data))

      if (code !== 0) {
        if (code == 402) {
          removeToken(context)
        }
        if (code !== 402) {
          if (code !== 8) {
            console.log('error');
            //notificationManager.openNotificationWithIcon('error', 'Erreur d\'identification', description)
          }
          else {
            console.log('error'); //.
            //notificationManager.openNotificationWithIcon('error', 'Erreur d\'identification', description)
          }
        }
        else {
          removeToken(context)
        }
      }
      else {
        const tokenData = res.data.data.ACCESSTOKEN
        context.commit('SET_TOKEN_PROPERTIES', tokenData)
        sessionStorage.setItem('tokenInfos', JSON.stringify(tokenData))
        await userRepository.getUserInfo(credentials.username)
          .then(res => {
            const code = res.data.code
            if (code !== 0) {
              if (code !== 402)
                console.log('error ' + 'Erreur de récupération de données ' + 'Une erreur est survenue lors de la récupération des informations de l\'utilisateur')
              else
                removeToken(context)
              console.log('error ' + 'Erreur de récupération de données ' + description)
            } else {
              const user = res.data.data
              context.commit('SET_USER', user)
              localStorage.setItem('userInfos', JSON.stringify(user))
            }
          })
        routes.push('/login', () => { })
      }
    })
}

const removeToken = (context) => {
  localStorage.clear()
  sessionStorage.clear()
  context.commit('REMOVE_TOKEN')
  routes.push("/login", () => { });
}

const setHeaderTitle = async (context, headerTitle) => {
  await context.commit('SET_HEADER_TITLE', headerTitle)
}

const updateUserInfos = async (context, user) => {
  let notif = { type: '', title: '', description: '', icon: '' };
  const infos = {
    livraison: user.livraison
  }
  await userRepository.updateUserInfos(user.email, infos).then(async function (res) {
    const code = res.data.code
    const description = res.data.description
    if (code !== 0) {
      notif.type = 'danger'; notif.title = 'Erreur'; notif.description = description; notif.icon = 'nc-icon nc-simple-remove';
      if (code !== 402)
        console.log(description); //.openNotificationWithIcon('error', 'Erreur de récupération de données', 'Une erreur est survenue lors de la récupération des informations de l\'utilisateur')
      else
        removeToken(context)
      console.log(description); //.openNotificationWithIcon('error', 'Erreur de récupération de données', res.data.description)
    } else {
      notif.type = 'success'; notif.title = 'OK'; notif.description = 'Mise à jour des informations réussie'; notif.icon = 'nc-icon nc-check-2';
      const user = res.data.data
      context.commit('SET_USER', user)
      localStorage.setItem('userInfos', JSON.stringify(user))
      console.log('changements: ' + description); //.openNotificationWithIcon('success', 'Modification de profil', res.data.description)
    }
  })
  return notif;
}


export default {
  changePassword,
  getAdminInfo,
  updateAdminInfo,
  getDeliveryAddress,
  getContactsInfos,
  getCountries,
  getHomeContent,
  getTokenProperties,
  removeToken,
  setHeaderTitle,
  updateUserInfos,
}