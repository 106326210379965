<template>
  <div class="user">
    <div class="photo">
      <v-gravatar email=hashEmail default-img='identicon' alt="Avatar" />
      <!-- <img src="static/img/faces/face-2.jpg"/> -->
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="#">
         <span>
           {{prenom}} {{nom}}
           <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" v-show="!isClosed">
            <li>
              <a href="#">
                <span class="sidebar-mini-icon">MP</span>
                <span class="sidebar-normal">Mon Profil</span>
              </a>
            </li>
            <li>
              <a href="#">
                <span class="sidebar-mini-icon">EP</span>
                <span class="sidebar-normal">Éditer le profil</span>
              </a>
            </li>
            <li>
              <a href="#">
                <span class="sidebar-mini-icon">P</span>
                <span class="sidebar-normal">Paramètres</span>
              </a>
            </li>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions'

  export default {
    components: {
      CollapseTransition
    },
    data() {
      return {
        isClosed: true,
        prenom:'',
        nom:'',
        hashEmail: null
      }
    },
    methods: {
      toggleMenu() {
        this.isClosed = !this.isClosed
      }
    },
    async mounted() {
      
      const userInfos = JSON.parse(localStorage.getItem("userInfos"));
      if(userInfos) {
        this.prenom = userInfos.PRENOM
        this.nom = userInfos.NOM
        this.hashEmail = 'de20f41d8145482'+userInfos.PRENOM+userInfos.NOM
      }
    },
  }
</script>
<style scoped>
  .nav.nav-menu {
    margin-top: 0;
  }
</style>
