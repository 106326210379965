<template>
  <div v-loading.lock="visibleSpin">
    <div class="row" v-show="!visibleSpin">
      <div class="col-md-12">
        <h4 class="title">Liste des utilisateurs</h4>
        <h5 v-if="defaultListUsers.length > 0">
          Vous avez accès à {{ defaultListUsers.length }} utilisateurs
        </h5>
        <h5 v-else>Vous n'avez accès à aucun utilisateur</h5>
      </div>
      <div class="col-md-12 card">
        <div class="card-header">
          <div class="category"></div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-sm-12 col-md-6">
              <div class="users-search">
                <fg-input
                  class="input-sm"
                  v-model="searchQuery"
                  placeholder="Rechercher par nom ou prénom"
                  addon-right-icon="nc-icon nc-zoom-split"
                  clearable
                >
                  <!--@input="onSearch"-->
                </fg-input>
                <div v-if="searchResults !== null">
                  {{ searchResults }} {{ commentResult }}
                </div>
              </div>
            </div>
            <div class="col-md-3"></div>
          </div>
        </div>
        <div class="card-body row">
          <div class="pagination-info col-sm-4">
            <p class="category">
              Affichage de {{ from + 1 }} à {{ to }} sur un total de
              {{ total }} entrées
            </p>
          </div>
          <div class="col-sm-6">
            <p-pagination
              class="pull-right"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </p-pagination>
          </div>
          <div class="rubrique-filtre col-sm-2">
            <span style="margin: 8px 0 8px 16px" class="pull-right">
              par page
            </span>
            <el-select
              size="sm"
              style="width: 80px"
              class="select-default pull-right"
              v-model="pagination.perPage"
              placeholder=""
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>

          <div class="col-sm-12 mt-2">
            <el-table
              v-show="!visibleSpin"
              class="table-striped"
              :data="queriedData"
              empty-text="Pas d\'utilisateure"
              :default-sort="{ prop: 'nom', order: 'ascending' }"
              stripe
              border
              style="width: 100%"
            >
              <!-- <el-table-column
                v-for="head in formThead"
                :key="head.value"
                :prop="head.value"
                :label="head.title"
                :min-width="head.celwidth == null ? 100 : head.celwidth"
                :sortable="head.sortable"
              >
                <template slot-scope="scope">
                  {{ scope.row[head.value] }}
                </template>
              </el-table-column>
             -->
              <el-table-column prop="nom" label="Nom" width="256">
                <template slot-scope="scope">
                  <div>{{ scope.row.CHAMPS[1].Valeur }}</div>
                </template>
              </el-table-column>

              <el-table-column prop="prenom" label="Prénom" width="256">
                <template slot-scope="scope">
                  <div>{{ scope.row.CHAMPS[2].Valeur }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="fonction"
                label="Fonction"
                :min-width="256"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.CHAMPS[3].Valeur }}</div>
                </template>
              </el-table-column>
              <el-table-column
                width="128"
                fixed="right"
                class-name="td-actions"
                label="Intervenir"
                style="align-items: center"
              >
                <template slot-scope="props">
                  <p-button type="info" icon @click="test(props.row)">
                    <i
                      aria-label="icon: shopping-cart"
                      class="nc-icon nc-zoom-split"
                      style="font-size: 24px"
                    >
                    </i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
            <br /><br />
          </div>
        </div>
      </div>
      <!-- Classic Modal -->
      <modal
        class="modal"
        v-if="visibleModal"
        :show.sync="modals.classic"
        headerClasses="justify-content-center"
      >
        <template slot="header">
          <h3 class="title title-up products-modal-gamme">
            Modif
            {{ oldValues.CHAMPS[2].Valeur + " " + oldValues.CHAMPS[1].Valeur }}
          </h3>
          <div>
            <p-button type="default" link @click="closeModal">Annuler</p-button>
            <p-button
              type="danger"
              link
              @click="validerChangements"
              :disabled="!modifWaiting"
              >Valider</p-button
            >
          </div>
        </template>

        <div v-for="(champ, index) in oldValues.CHAMPS" :key="index">
          <div class="modal-line" v-if="index > 0">
            <label class="modal-label" :for="champ.Nom">{{ champ.LIB }}</label>
            <input
              v-if="index <= 25"
              class="modal-champ"
              type="text"
              :name="champ.Nom"
              v-model="newValues.CHAMPS[index].Valeur"
              :placeholder="oldValues.CHAMPS[index].Valeur"
              :maxlength="champ.TailleMax"
              @change="onValueChange(index)"
            />
            <input
              v-if="index > 25"
              class="modal-champ"
              type="checkbox"
              :name="champ.Nom"
              v-model="newValues.CHAMPS[index].Valeur"
              :checked="champ.Valeur"
              @change="onValueChange(index)"
            />
          </div>
        </div>

        <template slot="footer">
          <div class="left-side">
            <p-button type="default" link @click="closeModal">Annuler</p-button>
          </div>
          <div class="divider"></div>
          <div class="right-side">
            <p-button
              type="danger"
              link
              @click="validerChangements"
              :disabled="!modifWaiting"
              >Valider</p-button
            >
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions } from "vuex";
import { Card, Button, Modal } from "src/components/UIComponents";
import PSwitch from "src/components/UIComponents/Switch.vue";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Checkbox,
  CheckboxGroup,
} from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
//   import users from './users'
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);

export default {
  components: {
    Card,
    Modal,
    [Button.name]: Button,
    [CheckboxGroup.name]: CheckboxGroup,
    PPagination,
    PSwitch,
  },
  computed: {
    pagedData() {
      return this.tableDataSource.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableDataSource.length;
        return this.pagedData;
      }
      let result = this.tableDataSource.filter((row, i) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row.CHAMPS[key].Valeur.toString();
          if (
            rowValue.includes &&
            rowValue.toUpperCase().includes(this.searchQuery.toUpperCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableDataSource.length;
      return this.tableDataSource.length;
    },
  },
  data() {
    return {
      modals: {
        classic: false,
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 50, 100, 150],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["1", "2"],
      searchInputValue: "",
      searchResults: null,
      commentResult: " résultats trouvés",
      tableDataSource: [],
      tableData: [],

      visibleSpin: true,
      visibleModal: false,

      utilisateurs: [],
      references: [],
      defaultListUsers: [],
      oldValues: null,
      newValues: null,
      modifWaiting: false,
      listeChangements: [],

      indeterminate: true,
    };
  },
  methods: {
    ...mapActions(["removeToken"]),
    test(user) {
      this.showModal(user);
    },
    // testSorting(a, b) {
    //   console.log(a.CHAMPS[1].Valeur)
    //   return a.CHAMPS[1].Valeur < b.CHAMPS[1].Valeur;
    // },
    async onValueChange(index) {
      let res =
        this.newValues.CHAMPS[index].Valeur !==
        this.oldValues.CHAMPS[index].Valeur;
      if (res) {
        this.modifWaiting = true;
      } else {
        this.modifWaiting = await this.verifAllModif();
      }
      console.log(
        "changement ? " +
          res +
          " " +
          this.oldValues.CHAMPS[index].Valeur +
          " " +
          this.newValues.CHAMPS[index].Valeur
      );
    },
    verifAllModif() {
      let res = false;
      let index = 1;
      while (index <= 51 && !res) {
        let temp =
          this.newValues.CHAMPS[index].Valeur !==
          this.oldValues.CHAMPS[index].Valeur;
        if (temp) {
          res = true;
        }
        index++;
      }
      return res;
    },
    showModal(user) {
      this.oldValues = user;
      this.newValues = JSON.parse(JSON.stringify(user));
      this.modals.classic = true;
      this.visibleModal = true;
      this.modifWaiting = false;
    },
    closeModal() {
      this.oldValues = null;
      this.newValues = null;
      this.listeChangements = [];
      this.modals.classic = false;
      this.visibleModal = false;
      this.modifWaiting = false;
    },
    async validerChangements() {
      let index = 1;
      this.listeChangements.push({
        Nom: "KTUSER",
        Valeur: this.newValues.CHAMPS[0].Valeur,
      });
      while (index <= 51) {
        let temp =
          this.newValues.CHAMPS[index].Valeur !==
          this.oldValues.CHAMPS[index].Valeur;
        if (temp) {
          this.listeChangements.push({
            Nom: this.newValues.CHAMPS[index].Nom,
            Valeur: this.newValues.CHAMPS[index].Valeur,
          });
        }
        index++;
      }
      const token = JSON.parse(sessionStorage.getItem("tokenInfos")).TOKEN;
      const stringifiedList = JSON.stringify(this.listeChangements)
      this.listeChangements = []
      const answer = await this.$store.dispatch("updateAdminInfo", {token: token, list: stringifiedList});
      this.$notify({
        type: answer.type,
        title: answer.title,
        message: answer.description,
        icon: answer.icon,
        horizontalAlign: "right",
        verticalAlign: "top",
      });

      //this.showAlert();
      this.closeModal();
      this.reload();
    },
    showAlert() {
      alert("Changements: \n" + JSON.stringify(this.listeChangements));
      this.closeModal();
      this.reload();
    },
    onSearch() {
      this.tableData = this.queriedData;
      //   const searchValues = this.searchInputValue.trim().split("*");
      //   if (searchValues.length !== null && searchValues.length > 0) {
      //     this.utilisateurs = this.defaultListUsers.filter((user) => {
      //       let result = true;
      //       searchValues.forEach((value) => {
      //         if (
      //           user.CHAMPS[1].Valeur.toUpperCase().includes(
      //             value.toUpperCase()
      //           ) ||
      //           user.CHAMPS[2].Valeur.toUpperCase().includes(
      //             value.toUpperCase()
      //           ) ||
      //           user.CHAMPS[3].Valeur.toUpperCase().includes(value.toUpperCase())
      //         ) {
      //           console.log("search1");
      //         } else {
      //           result = false;
      //           return;
      //         }
      //       });
      //       return result;
      //     });
      //     if (this.searchInputValue.length === 0) {
      //       this.searchResults = null;
      //     } else {
      //       this.searchResults = this.utilisateurs.length;
      //     }
      //     if (this.utilisateurs.length === 0) {
      //       this.commentResult = "aucun résultat";
      //     } else if (this.utilisateurs.length === 1) {
      //       this.commentResult = " résutat trouvé";
      //     } else {
      //       this.commentResult = " résultats trouvés";
      //     }
      //   } else {
      //     this.utilisateurs = this.defaultListUsers;
      //     this.searchResults = null;
      //     this.commentResult = "";
      //   }
    },
    async reload() {
      this.visibleSpin = true;
      const token = JSON.parse(sessionStorage.getItem("tokenInfos")).TOKEN;
      const answer = await this.$store.dispatch("getAdminInfo", token);
      this.$notify({
        type: answer.type,
        title: answer.title,
        message: answer.description,
        icon: answer.icon,
        horizontalAlign: "right",
        verticalAlign: "top",
      });

      this.references = JSON.parse(
        localStorage.getItem("adminInfos")
      ).REFERENCES;
      this.utilisateurs = JSON.parse(
        localStorage.getItem("adminInfos")
      ).UTILISATEURS;

      this.defaultListUsers = this.utilisateurs;
      this.tableDataSource = this.defaultListUsers;
      this.tableData = this.pagedData;
      localStorage.removeItem("adminInfos");

      this.visibleSpin = false;
    },
  },
  async mounted() {
    this.visibleSpin = true;
    const token = JSON.parse(sessionStorage.getItem("tokenInfos"));
    if (token) {
      const date = new Date(token.EXPIRATION).getTime();
      const now = new Date().getTime();
      if (date <= now) {
        alert("Token expiré. \nVeuillez vous reconnecter.");
        this.removeToken("tokenInfos");
        this.$router.push("/login", () => {});
      } else {
        console.log("entrée dans Liste Utilisateurs");
        const titleHeader = {
          title: "Liste Utilisateurs",
          subTitle: "Vous avez accès à " + "..." + " utilisateurs",
          icon:
            '<i aria-label="icon: unordered-list" class="anticon anticon-unordered-list"><svg viewBox="64 64 896 896" data-icon="unordered-list" width="1em" height="1em" aria-hidden="true" focusable="false" class=""><path d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0z"></path></svg></i>',
        };
        await this.$store.dispatch("setHeaderTitle", titleHeader);

        const token = JSON.parse(sessionStorage.getItem("tokenInfos")).TOKEN;
        const answer = await this.$store.dispatch("getAdminInfo", token);
        this.$notify({
          type: answer.type,
          title: answer.title,
          message: answer.description,
          icon: answer.icon,
          horizontalAlign: "right",
          verticalAlign: "top",
        });

        this.references = JSON.parse(
          localStorage.getItem("adminInfos")
        ).REFERENCES;
        this.utilisateurs = JSON.parse(
          localStorage.getItem("adminInfos")
        ).UTILISATEURS;

        this.defaultListUsers = this.utilisateurs;
        this.tableDataSource = this.defaultListUsers;
        this.tableData = this.pagedData;
        localStorage.removeItem("adminInfos");

        this.visibleSpin = false;
      }
    } else {
      this.$router.push("/login", () => {});
    }
  },
};
</script>
<style lang="scss">
.rubrique-filtre {
  margin-bottom: 16px;
}
.el-table .td-actions {
  button.btn {
    margin: auto;
    text-align: center;
  }
}
.el-table .cell {
  text-align: center;
}
.users {
  background: white;
  &-datatable {
    margin-top: 25px;
    width: 95%;
    margin-left: 2.5%;
    //white-space: pre-line;
    &-stock {
      width: 10%;
      // max-width: 350px;
      // min-width: 150px;
    }
  }
  &-filters {
    padding: 10px;
    width: 300px;
    max-width: 100%;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    &-all {
      margin-bottom: 15px;
    }
    &-list {
      display: flex;
      flex-direction: column;
    }
  }
  &-header {
    //display: flex;
    //position: fixed;
    top: 75px;
    left: 215px;
    right: 15px;
    z-index: 2;
    justify-content: space-between;
    padding-top: 50px;
    padding: 25px;
    background: white;
  }
  &-modal {
    &-comm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      //align-items: center;
      textarea,
      button {
        margin-top: 15px;
      }
      button {
        width: 50%;
      }
    }
    &-lib {
      text-align: center;
      white-space: pre-line;
      //padding: 0 20px;
    }
    &-lib {
      padding-bottom: 25px;
    }
    &-price {
      height: 150px;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
    }
    &-sell {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-total {
      margin-top: 5px;
      font-weight: bold;
    }
    &-taxe {
      margin-bottom: 5px;
      color: #22844e;
      font-size: 10px;
    }
    &-tranche {
      width: 50%;
      display: flex;
      flex-direction: column;
      //align-items: flex-start;
      padding-left: 20px;
      margin-top: 20px;
    }
  }
  &-search {
    width: 100%;
    height: 40px;
    margin: 32px auto;
    padding-bottom: 32px;
  }
}
.modal {
  width: 100%;
  &-dialog {
    width: 700px;
    max-width: 100%;
  }
  &-content {
    width: 800px;
    max-width: 100%;
  }
  // &-line {
  //   border-top: 1px dotted black;
  // }
  &-label {
    width: 33%;
  }
  &-champ {
    width: 67%;
  }
}
h1 {
  font-size: 32px;
  text-align: center;
}
p,
li {
  font-size: 16px;
  text-align: justify;
}
.category {
  font-size: 14px !important;
}
</style>
