<template>
  <navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button
          id="minimizeSidebar"
          class="btn btn-icon btn-round"
          @click="minimizeSidebar"
        >
          <i
            class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"
          ></i>
          <i
            class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"
          ></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar">
        </navbar-toggle-button>
      </div>
      <a class="navbar-brand" href="#">Centre d'Aide</a>
    </div>

    <template slot="navbar-menu">
      <!-- <form>
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Rechercher...">
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="nc-icon nc-zoom-split"></i>
            </div>
          </div>
        </div>
      </form> -->
      <ul class="navbar-nav">
        <!-- <li class="nav-item">
          <a class="nav-link btn-magnify" href="#">
            <i class="nc-icon nc-layout-11"></i>
            <p>
              <span class="d-lg-none d-md-block">Statistiques</span>
            </p>
          </a>
        </li> -->
        <drop-down
          icon="nc-icon nc-bell-55"
          tag="li"
          position="right"
          direction="none"
          class="nav-item btn-rotate dropdown"
        >
          <a
            slot="title"
            slot-scope="{ isOpen }"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            :aria-expanded="isOpen"
          >
            <i class="nc-icon nc-bell-55"></i>
            <p>
              <span class="d-lg-none d-md-block">Actions </span>
            </p>
          </a>
          <a class="dropdown-item" href="#">Une action</a>
          <a class="dropdown-item" href="#">Une autre action</a>
          <a class="dropdown-item" href="#">Quelque chose d'autre</a>
        </drop-down>
        <drop-down
          icon="nc-icon nc-bell-55"
          tag="li"
          position="right"
          direction="none"
          class="nav-item btn-rotate dropdown"
        >
          <a
            slot="title"
            slot-scope="{ isOpen }"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            :aria-expanded="isOpen"
          >
            <i class="nc-icon nc-settings-gear-65"></i>
            <p>
              <span class="d-lg-none d-md-block">Mon Compte </span>
            </p>
          </a>
          <a class="dropdown-item" href="#/profile">Mes Informations</a>
          <a class="dropdown-item" href="#/commandes">Mes Commandes</a>
          <a class="dropdown-item" href="#/contact">Mes Contacts</a>
        </drop-down>

        <li class="nav-item" @click="logout" style="max-width: 200px">
          <a class="nav-link btn-rotate" href="">
            <i aria-label="icon: logout" title="">
              <svg
                viewBox="64 64 896 896"
                data-icon="logout"
                width="30px"
                height="30px"
                fill="currentColor"
                aria-hidden="true"
                focusable="false"
              >
                <path
                  d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 0 1-112.7 75.9A352.8 352.8 0 0 1 512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 0 1-112.7-75.9 353.28 353.28 0 0 1-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 7.9 7.9 15.3 16.1 22.4 24.5 3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82 271.7 82.6 79.6 277.1 82 516.4 84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7 3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6z"
                ></path>
              </svg>
            </i>
            <p>
              <span class="d-lg-none d-md-block">Déconnexion</span>
            </p>
          </a>
        </li>
      </ul>
    </template>
  </navbar>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Navbar, NavbarToggleButton } from "src/components/UIComponents";

export default {
  components: {
    Navbar,
    NavbarToggleButton,
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
      currentRoute: ["/admin/overview"],

    };
  },
  computed: {
    ...mapState([
      "headerTitle",
    ]),
  },
  methods: {
    ...mapActions([
      "removeToken",
    ]),
    logout() {
      this.removeToken("tokenInfos");

      routes.push("/login", () => {});
      //window.Kommunicate.logout();
    },
    mounted() {},

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
  },
};
</script>
<style lang="scss">
.cart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1;
  color: #002043;
}
.cartLength {
  position: absolute;
  border: 2px solid;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  bottom: 25px;
  left: 35px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  //background: #f4f3ef;
  font-size: large;
  font-weight: 900;
  padding: 10px;

  z-index: 10;
}
</style>
