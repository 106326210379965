<template>
  <div :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications transition-name="notification-list" transition-mode="out-in">
    </notifications>
    <router-view name="header"></router-view>
    <span v-if="mode === 'development'" style="color: red; position: fixed; top:0;right:45%; z-index:10;"> {{ mode }} mode </span>
      
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
// Loading some plugin css asynchronously
import "sweetalert2/dist/sweetalert2.css";
import "vue-notifyjs/themes/default.css";
export default {
  data() {
    return {
      //fr_FR,
      mode: process.env.NODE_ENV,
    };
  },
  mounted() {
    if (process.env.NODE_ENV === "development") {
      console.log("running development mode");
    } else {
      console.log("running production mode");
    }
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


/* ################ Notifications ############### */
.alert, .notification, .vue-notifyjs {
  z-index: 2048 !important;
  // opacity: 0.8 !important;
  font-size: 20px !important;
}

/* ################ Les Boutons et Liens ################ */
.divLiens {
  margin: auto;
  width: 100%;
  align-self: center;
}
.mesLiens {
  color: #002043;
  cursor: pointer;
  margin: auto;
  text-align: center;
  font-size: 12px;
  width: 100%;
  &-pass {
    width: 60%;
  }
  &-creaCompte {
    width: 55%;
  }
}
.mesLiens:hover {
  color: #004794;
  text-decoration: none;
}

/* #################### Zone d'erreur formlaire ################### */
.messageErreur {
  width: 100%;
  height: 32px;
  margin: 0 auto 8px auto;
  text-align: center;
  color: #f00;
}
</style>
