<template>
  <div class="hello">
    <DashboardLayout />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DashboardLayout from "@/components/Dashboard/Layout/DashboardLayout.vue";
export default {
  name: "Welcome",
  components: {
    DashboardLayout,
  },
  methods: {
    ...mapActions(["removeToken"]),
  },
  mounted() {
    const token = JSON.parse(sessionStorage.getItem("tokenInfos"));
    if (token) {
      const date = new Date(token.EXPIRATION).getTime();
      const now = new Date().getTime();
      if (date <= now) {
        this.removeToken("tokenInfos");
      } else {
        if (this.$store.state.token === null) {
          this.$store.state.token = token;
        }
        this.$router.push("/liste-utilisateurs", () => {});
      }
    } else {
      this.$router.push("/login", () => {});
    }
  },
};
</script>

<style lang="scss" scoped>
</style>