<template>
  <div class="wrapper">
    <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks">
      <user-menu></user-menu>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import UserMenu from "src/components/UIComponents/SidebarPlugin/UserMenu.vue";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    UserMenu,
  },
  data() {
    return {
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  async mounted() {
    const token = JSON.parse(sessionStorage.getItem("tokenInfos"));
    if (token) {
      const date = new Date(token.EXPIRATION).getTime();
      const now = new Date().getTime();
      if (date <= now) {
        alert("Token expiré. \nVeuillez vous reconnecter.");
        this.removeToken("tokenInfos");
        this.$router.push("/login", () => {});
      } else {
        console.log("affichage de Dashboard Layout");
      }
    } else {
      this.$router.push("/login", () => {});
    }
  },
};
</script>
