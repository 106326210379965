export default [
  {
    name: 'Liste utilisateurs',
    icon: 'fa fa-user',
    path: '/liste-utilisateurs'
  },
  // {
  //   name: 'Accueil',
  //   icon: 'fa fa-home',
  //   path: '/admin/overview'
  // },
  // {
  //   name: 'Informations',
  //   icon: 'nc-icon nc-alert-circle-i',
  //   path: '/profile'
  // },
  // {
  //   name: 'Mes Contacts',
  //   icon: 'fa',
  //   path: '/contact'
  // },
  {
    name: 'Components',
    icon: 'nc-icon nc-layout-11',
    children: [
    {
      name: 'Icons',
      path: '/components/icons'
    }
  ]
  },
]
