<template>
  <div class="row profile" v-loading.lock="visibleSpin">
    <ValidationObserver
      ref="form"
      v-slot="{ handleSubmit }"
      v-show="!visibleSpin"
    >
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="col-md-12">
          <card>
            <h1 slot="header" class="card-title">Facturation</h1>
            <hr />
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="factContactNom"
                  rules="required"
                >
                  <fg-input
                    v-model="form.factContactNom"
                    label="Nom du contact de facturation"
                    disabled
                  ></fg-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="factContactMail"
                  rules="required"
                >
                  <fg-input
                    v-model="form.factContactMail"
                    label="Mail du contact de facturation"
                    disabled
                  ></fg-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="factContactTel"
                  rules="required"
                >
                  <fg-input
                    v-model="form.factContactTel"
                    label="Tel du contact de facturation"
                    disabled
                  ></fg-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider mode="lazy" name="factNom" rules="required">
                  <fg-input
                    v-model="form.factNom"
                    label="Raison sociale"
                    disabled
                  ></fg-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="factRue1"
                  rules="required"
                >
                  <fg-input
                    v-model="form.factRue1"
                    label="Rue 1"
                    disabled
                  ></fg-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="factRue2"
                  rules="required"
                >
                  <fg-input
                    v-model="form.factRue2"
                    label="Rue 2"
                    disabled
                  ></fg-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="factPostal"
                  rules="required"
                >
                  <fg-input
                    v-model="form.factPostal"
                    label="Code Postal"
                    disabled
                  ></fg-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="factVille"
                  rules="required"
                >
                  <fg-input
                    v-model="form.factVille"
                    label="Ville"
                    disabled
                  ></fg-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="selectedBillingCountry"
                  rules="required"
                >
                  <fg-input
                    v-model="selectedBillingCountry"
                    label="Pays"
                    disabled
                  ></fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="profile-delivery">
              <div class="profile-billing-form"></div>
              <hr />
              <p class="profile-billing-info">
                {{ bankInfos }}
                <router-link :to="{ name: 'Contact' }"
                  >Mes contacts</router-link
                >
              </p>
            </div>
          </card>
        </div>
        <div class="col-md-12">
          <card>
            <h1 slot="header" class="card-title">
              Adresse de livraison par défaut
            </h1>
            <hr />
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="livrContactNom"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <fg-input
                    @change="onItemChange"
                    type="text"
                    v-model="form.livrContactNom"
                    label="Nom du contact de livraison"
                  >
                  </fg-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="livrContactMail"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <fg-input
                    @change="onItemChange"
                    type="email"
                    v-model="form.livrContactMail"
                    label="Mail du contact de livraison"
                  ></fg-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="livrContactTel"
                  rules=""
                  v-slot="{ errors }"
                >
                  <fg-input
                    @change="onItemChange"
                    type="phone"
                    v-model="form.livrContactTel"
                    label="Tel du contact de livraison"
                  ></fg-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="livrNom"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <fg-input
                    @change="onItemChange"
                    type="text"
                    v-model="form.livrNom"
                    label="Raison sociale"
                  ></fg-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="livrRue1"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <fg-input
                    @change="onItemChange"
                    type="text"
                    v-model="form.livrRue1"
                    label="Rue 1"
                  ></fg-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="livrRue2"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <fg-input
                    @change="onItemChange"
                    type="text"
                    v-model="form.livrRue2"
                    label="Rue 2"
                  ></fg-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="livrPostal"
                  rules="required|numeric|max:5"
                  v-slot="{ errors }"
                >
                  <fg-input
                    @change="onItemChange"
                    type="text"
                    v-model="form.livrPostal"
                    label="Code Postal"
                  ></fg-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  mode="lazy"
                  name="livrVille"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <fg-input
                    @change="onItemChange"
                    type="text"
                    v-model="form.livrVille"
                    label="Ville"
                  ></fg-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider mode="lazy" name="pays" rules="required">
                  <fg-input name="pays" label="Pays">
                    <el-select
                      class="select-default"
                      v-model="selectedDeliveryCountry"
                      @change="
                        handleDeliverySelectChange(selectedDeliveryCountry)
                      "
                      placeholder=" - Pays - "
                      addon-left-icon="nc-icon nc-globe"
                    >
                      <el-option
                        class="select-primary"
                        v-for="(dcountry, index) in countries"
                        :label="dcountry.CHAINES[0]"
                        :key="index"
                        :value="dcountry.CHAINES[2]"
                      >
                      </el-option>
                    </el-select>
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
          </card>
        </div>
        <div class="col-md-12">
          <card>
            <h5 slot="header" class="card-title">
              Instructions de livraison par défaut
            </h5>
            <fieldset>
              <div class="form-group">
                <div class="col-12">
                  <ValidationProvider
                    mode="lazy"
                    name="livrInstructions"
                    rules="max:40"
                    message="Maximum 40 caractères"
                    v-slot="{ errors }"
                  >
                    <textarea
                      @change="onItemChange"
                      class="form-control"
                      v-model="form.livrInstructions"
                      placeholder="exemple : - appeler 20 minutes avant d'arriver"
                      rows="3"
                      max="40"
                    ></textarea>
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </fieldset>
          </card>
        </div>
        <div class="validation">
          <p-button
            class="formButton"
            :disabled="!formChange"
            type="submit"
            native-type="submit"
            @click="onSubmit"
          >
            ENREGISTRER
          </p-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Card } from "src/components/UIComponents";
import { extend } from "vee-validate";
import { Input, Button, Select, Option } from "element-ui";
import { required, email, alpha, numeric, max } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Ce champ est requis !",
});
extend("email", email);
extend("alpha", alpha);
extend("numeric", {
  validate(value) {
    return value / 1;
  },
  params: ["value"],
  message: "Veuillez n'entrer que des chiffres",
});
extend("max", {
  validate(value, { length }) {
    return value.length <= length;
  },
  params: ["length"],
  message: "Maximum {length} caractères",
});

export default {
  components: {
    Card,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      form: {
        factContactNom: "",
        factContactMail: "",
        factContactTel: "",
        factNom: "",
        factRue1: "",
        factRue2: "",
        factPostal: "",
        factVille: "",

        livrContactNom: "",
        livrContactMail: "",
        livrContactTel: "",
        livrNom: "",
        livrRue1: "",
        livrRue2: "",
        livrPostal: "",
        livrVille: "",

        livrInstructions: "",
      },
      //form: this.$form.createForm(this, { name: 'cart_validation' }),
      visibleSpin: true,
      userInfos: {},
      countries: [],
      selectedBillingCountry: null,
      selectedDeliveryCountry: null,
      formChange: false,
      bankInfos:
        "Pour modifier vos informations bancaires, merci de prendre contact avec le service de comptabilté dans la rubrique ",
    };
  },
  methods: {
    ...mapActions(["removeToken"]),
    submit() {
      this.onSubmit();
      alert("Changements effectués !");
    },
    onItemChange() {
      if (!this.formChange) this.formChange = !this.formChange;
    },
    handleBillingSelectChange(value) {
      this.selectedBillingCountry = value;
    },
    handleDeliverySelectChange(value) {
      this.selectedDeliveryCountry = value;
      this.onItemChange();
    },
    async onSubmit() {
      //e.preventDefault();
      console.log("submitting changes");
      this.formChange = !this.formChange;
      const user = {
        email: this.userInfos.LOGIN,
        livraison: {
          CONTACT: {
            NOM: this.form.livrContactNom.trim(),
            MAIL: this.form.livrContactMail.trim(),
            TEL: this.form.livrContactTel,
          },
          NOM: this.form.livrNom.trim(),
          RUE1: this.form.livrRue1.trim(),
          RUE2: this.form.livrRue2.trim(),
          CODEPOSTAL: this.form.livrPostal,
          VILLE: this.form.livrVille.trim(),
          CODEPAYS: this.selectedDeliveryCountry.trim(),
          INSTRUCTION: this.form.livrInstructions.trim(),
        },
      };
      let answer = await this.$store.dispatch("updateUserInfos", user);
      this.$notify({
        type: answer.type,
        title: answer.title,
        message: answer.description,
        icon: answer.icon,
        horizontalAlign: "right",
        verticalAlign: "top",
      });
      console.log("changes OK !");
      this.userInfos = await JSON.parse(localStorage.getItem("userInfos"));
    },
  },
  async mounted() {
    this.visibleSpin = true;
    const token = JSON.parse(sessionStorage.getItem("tokenInfos"));
    if (token) {
      const date = new Date(token.EXPIRATION).getTime();
      const now = new Date().getTime();
      if (date <= now) {
        this.removeToken("tokenInfos");
      } else {
        const titleHeader = {
          title: "Mes informations",
          subTitle: "",
          icon:
            '<i aria-label="icon: user" class="anticon anticon-user"><svg viewBox="64 64 896 896" data-icon="user" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M858.5 763.6a374 374 0 0 0-80.6-119.5 375.63 375.63 0 0 0-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 0 0-80.6 119.5A371.7 371.7 0 0 0 136 901.8a8 8 0 0 0 8 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 0 0 8-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"></path></svg></i>',
        };
        await this.$store.dispatch("setHeaderTitle", titleHeader);
        console.log("récup des infos utilisateur");
        this.userInfos = await JSON.parse(localStorage.getItem("userInfos"));
        this.countries = await JSON.parse(
          localStorage.getItem("countriesInfos")
        );
        this.form.factContactNom = this.userInfos.FACTURATION.CONTACT.NOM;
        this.form.factContactMail = this.userInfos.FACTURATION.CONTACT.MAIL;
        this.form.factContactTel = this.userInfos.FACTURATION.CONTACT.TEL;
        this.form.factNom = this.userInfos.FACTURATION.NOM;
        this.form.factRue1 = this.userInfos.FACTURATION.RUE1;
        this.form.factRue2 = this.userInfos.FACTURATION.RUE2;
        this.form.factPostal = this.userInfos.FACTURATION.CODEPOSTAL;
        this.form.factVille = this.userInfos.FACTURATION.VILLE;
        this.selectedBillingCountry = this.userInfos.FACTURATION.CODEPAYS;

        this.form.livrContactNom = this.userInfos.LIVRAISON.CONTACT.NOM;
        this.form.livrContactMail = this.userInfos.LIVRAISON.CONTACT.MAIL;
        this.form.livrContactTel = this.userInfos.LIVRAISON.CONTACT.TEL;
        this.form.livrNom = this.userInfos.LIVRAISON.NOM;
        this.form.livrRue1 = this.userInfos.LIVRAISON.RUE1;
        this.form.livrRue2 = this.userInfos.LIVRAISON.RUE2;
        this.form.livrPostal = this.userInfos.LIVRAISON.CODEPOSTAL;
        this.form.livrVille = this.userInfos.LIVRAISON.VILLE;
        this.selectedDeliveryCountry = this.userInfos.LIVRAISON.CODEPAYS;
        this.form.livrInstructions = this.userInfos.LIVRAISON.INSTRUCTION;
        this.visibleSpin = false;
      }
    } else {
      this.$router.push("/login", () => {});
    }
  },
};
</script>

<style lang="scss" scoped>
.profile {
  padding: 20px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }
  &-billing,
  &-delivery {
    margin-top: 25px;
  }
  &-address {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: column;
    &-form {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
  &-delivery {
    &-instruction {
      width: 97%;
      margin-left: 1.5%;
    }
  }
}
.delivery {
  &-contact {
    display: flex;
    flex-direction: column;
    width: 30%;
  }
}
.validation {
  width: 97%;
  display: flex;
  justify-content: center;
  .formButton {
    width: 300px;
    margin: auto;
  }
}
</style>